module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://decode.usermd.net/cms/graphql","schema":{"perPage":40,"timeout":600000,"requestConcurrency":15,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"debug":{"graphql":{"showQueryVarsOnError":true,"writeQueriesToDisk":true,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"FeaturedParentsArticle":{"exclude":true},"FeaturedGrandparentsArticle":{"exclude":true},"FeaturedDontMissArticleInHomePage":{"exclude":true},"FeaturedMedicalsArticle":{"exclude":true},"FeaturedProduct":{"exclude":true},"Guarantee":{"exclude":true},"Banner":{"exclude":true},"ArticleAuthor":{"exclude":true},"GuaranteeProduct":{"exclude":true},"Guide":{"exclude":true},"Accessory":{"exclude":true},"Color":{"exclude":true},"Article":{"exclude":true},"MapPlace":{"exclude":true},"PostFormat":{"exclude":true},"Rodo":{"exclude":true},"TopBar":{"exclude":true},"PrivacyPolicy":{"exclude":true},"Slider":{"exclude":true},"Video":{"exclude":true},"Category":{"exclude":true},"Comment":{"exclude":true},"Menu":{"exclude":true},"Tag":{"exclude":true},"Taxonomy":{"exclude":true},"User":{"exclude":true},"UserRole":{"exclude":true},"Page":{"exclude":true},"Post":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"MediaItem":{"exclude":true,"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/logo_round.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"432f591a5fe4ff3078203535405955f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl","en","de","fr"],"defaultLanguage":"en","trailingSlash":"always"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
